import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';

import PageTitle from '../components/page-title';
import CompaniesGrid from '../components/companies-grid';
import LargeCallout from '../components/large-callout';

const Companies = ({ data }) => {
  const pageContent = data.allPrismicCompanies.edges[0].node.data;

  return (
    <Layout>
      <SEO title={pageContent.page_title.text} />
      <PageTitle
        title={pageContent.page_title.text}
        spacing="u-mar-top-xxl u-mar-bottom-xl md:u-mar-top-xxxl sm-md:u-mar-bottom-xxl md:u-pad-bottom-m"
      />

      <div className="o-container-fluid">
        <CompaniesGrid companies={pageContent.companies} />
        <LargeCallout content={pageContent.callout} />
      </div>
    </Layout>
  );
};

export default withPreview(Companies);

export const query = graphql`
  {
    allPrismicCompanies {
      edges {
        node {
          prismicId
          data {
            page_title {
              text
            }
            companies {
              company {
                document {
                  ... on PrismicCompany {
                    uid
                    data {
                      company_name {
                        text
                      }
                      company_tagline {
                        text
                      }
                      company_logo_white {
                        url
                        alt
                      }
                      founder {
                        headshot {
                          fluid(maxWidth: 800) {
                            ...GatsbyPrismicImageFluid
                          }
                          alt
                        }
                      }
                    }
                  }
                }
              }
            }
            callout {
              document {
                ... on PrismicGetInTouch {
                  id
                  data {
                    get_in_touch_content {
                      html
                    }
                    get_in_touch_cta {
                      text
                      link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
