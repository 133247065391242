import React from 'react';
import { Link } from 'gatsby';

import Button from '../button';

import './companies-grid.module.scss';

const CompaniesGrid = ({ companies }) => (
  <ul className="o-row" styleName="companies-list">
    {companies.map((item, i) => {
      const company = item.company.document;
      const {
        company_name,
        company_tagline,
        founder,
        company_logo_white,
      } = company.data;

      return (
        <li className="o-col o-col-xs-sm-6 o-col-md-4" key={i}>
          <Link
            to={`/companies/${company.uid}`}
            key={i}
            styleName="company-link"
          >
            <div
              styleName={`company-link__images company-link__images--${founder.length}`}
            >
              <img
                src={company_logo_white.url}
                alt={company_logo_white.alt}
                styleName="logo"
              />
              {founder.map((image, i) => (
                <img
                  src={image.headshot.fluid.src}
                  alt={image.headshot.alt}
                  key={i}
                />
              ))}
            </div>
            <div styleName="company-link__content">
              <h2>{company_name.text}</h2>
              <p>{company_tagline.text}</p>

              <Button
                theme={'text--light'}
                size={'small'}
                text={'Learn more'}
                withArrow
              />
            </div>
          </Link>
        </li>
      );
    })}
  </ul>
);

export default CompaniesGrid;
